window.Vue = require('vue');
window.axios = require('axios');
window.lodash = require('lodash');

import vmodal from 'vue-js-modal'
Vue.use(vmodal);

import hljs from 'highlight.js';
import 'highlight.js/styles/stackoverflow-dark.css';
Vue.use(hljs.vuePlugin);

Vue.component('ef2-modal-container', require('../vue/components/ModalContainer').default);
Vue.component('ef2-modal-trigger', require('../vue/components/ModalTrigger').default);
Vue.component('ef2-show-code', require('../vue/components/ShowCode').default);

new Vue({
    'el': '#app',

    comments: true,

    data() {
        return {
            modalBus: new Vue()
        }
    }
});