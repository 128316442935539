<template>
    <div class="vue-modal-container">
        <modal
            :id="modalId"
            :name="modalId"
            width="90%"
            :maxWidth="950"
            :adaptive="true"
            height="auto"
            :scrollable="true"
            @before-close="handleClose"
        >
            <div class="modal-inner-wrapper">
                <a class="close-button" href="" @click.prevent="close">x</a>

                <img :src="src" alt="Alt tekst">
            </div>
        </modal>
    </div>
</template>

<script>
export default {
    name: "ModalContainer",

    props: ["modalBus"],

    data() {
        return {
            src: ""
        };
    },

    mounted() {
        this.modalBus.$on("show-modal", (payload) => {
            this.src = payload.src;

            this.show();
        });
    },

    computed: {
        modalId() {
            return _.uniqueId("iframe-modal-");
        }
    },

    methods: {
        show() {
            document.documentElement.style.overflowY = "hidden";
            document.documentElement.style.height = "100vh";

            this.$modal.show(this.modalId);
        },

        close() {
            this.$modal.hide(this.modalId);
        },

        handleClose() {
            document.documentElement.style.overflowY = "auto";
            document.documentElement.style.height = "auto";
        }
    }
}
</script>