var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vue-show-code" },
    [
      _c(
        "button",
        {
          staticClass: "btn btn-secondary",
          on: {
            click: function($event) {
              _vm.show = !_vm.show
            }
          }
        },
        [_vm._v("Show Template")]
      ),
      _vm._v(" "),
      _vm.show
        ? [
            _c(
              "div",
              { staticClass: "mt-3" },
              [
                _c("highlightjs", {
                  attrs: { autodetect: "", code: _vm.codeBlock }
                })
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }