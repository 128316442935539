<template>
    <div class="vue-show-code">
        <button class="btn btn-secondary" @click="show = ! show">Show Template</button>

        <template v-if="show">
            <div class="mt-3">
                <highlightjs autodetect :code="codeBlock" />
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: "ShowCode",

    data() {
        return {
            show: false
        }
    },

    computed: {
        codeBlock() {
            return this.$slots.default[0].text;
        }
    }
}
</script>