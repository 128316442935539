<template>
    <div class="vue-modal-trigger">
        <a @click.prevent="callModal" oncontextmenu="return false;" href="#" class="btn btn-primary">Show modal</a>
    </div>
</template>

<script>
export default {
    name: "ModalTrigger",

    props: ['modalBus', 'modalSrc'],

    methods: {
        callModal() {
            this.modalBus.$emit('show-modal', {
                src: this.modalSrc
            });
        }
    }
}
</script>