var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vue-modal-container" },
    [
      _c(
        "modal",
        {
          attrs: {
            id: _vm.modalId,
            name: _vm.modalId,
            width: "90%",
            maxWidth: 950,
            adaptive: true,
            height: "auto",
            scrollable: true
          },
          on: { "before-close": _vm.handleClose }
        },
        [
          _c("div", { staticClass: "modal-inner-wrapper" }, [
            _c(
              "a",
              {
                staticClass: "close-button",
                attrs: { href: "" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.close($event)
                  }
                }
              },
              [_vm._v("x")]
            ),
            _vm._v(" "),
            _c("img", { attrs: { src: _vm.src, alt: "Alt tekst" } })
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }