var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-modal-trigger" }, [
    _c(
      "a",
      {
        staticClass: "btn btn-primary",
        attrs: { oncontextmenu: "return false;", href: "#" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.callModal($event)
          }
        }
      },
      [_vm._v("Show modal")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }